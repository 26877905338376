let menuButtons = document.querySelectorAll('.has-submenu');
let Header = document.querySelector('header')
let Submenus = document.querySelectorAll('.secondary-nav');
let Submenu = document.querySelector('.secondary-nav-wrapper')
let MenuToggler = document.querySelector('.menu-toggler');
let MenuCLoser = document.querySelector('.menu-closer');
let BackButton = document.querySelectorAll('.back-button');
let SecondaryNav = document.querySelectorAll('.secondary-nav');
let idTarget
let toActivate
let search = document.querySelector('.search-site')
let Menu = document.querySelector('.main-nav')


//-mobile menu opener
MenuToggler.onclick = function() {
	Menu.classList.add('opened');
}
//-mobile menu closer
MenuCLoser.onclick = function() {
	Menu.classList.remove('opened');
	Submenus.forEach((el) => {
		el.classList.remove("opened")
	})
	menuButtons.forEach((el) => {
		el.classList.remove("opened")
	})
	Header.classList.remove('opened')
}
//mobile submenu closer
BackButton.forEach((el)=> {
	el.onclick = function() {
		SecondaryNav.forEach((el) => {
			el.classList.remove("opened")
		})
	}
})
if (search) {
	search.onclick = function(){
		Submenus.forEach((el) => {
			el.classList.remove("opened")
		})
		menuButtons.forEach((el) => {
			el.classList.remove("opened")
		})
		Header.classList.remove('opened')
	}
}


document.addEventListener('click', event => {
	const isClickInside = Header.contains(event.target)
	if (!isClickInside) {
		Submenus.forEach((el) => {
			el.classList.remove("opened")
		})
		menuButtons.forEach((el) => {
			el.classList.remove("opened")
		})
		Header.classList.remove('opened')
	}
})

menuButtons.forEach((element) => {
	element.onclick = function(){
		if (this.classList.contains('opened')) {
			Submenus.forEach((el) => {
				el.classList.remove("opened")
			})
			menuButtons.forEach((el) => {
				el.classList.remove("opened")
			})
			Header.classList.remove('opened')
		} else {
			Submenus.forEach((el) => {
				el.classList.remove("opened")
			})
			menuButtons.forEach((el) => {
				el.classList.remove("opened")
			})
			Header.classList.remove('opened')
			idTarget = element.getAttribute('data-rel')
			toActivate = document.querySelector('#'+idTarget)
			Header.classList.add('opened')
			element.classList.add('opened')
			toActivate.classList.add('opened')
		}
	}
});


