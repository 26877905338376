let submenuToggler = document.querySelector(".submenu-toggler")
let sideBar = document.querySelector(".sidebar-wrapper")
let links = document.querySelectorAll(".sidebar-wrapper a")


if (sideBar) {
	submenuToggler.onclick = function() {
		sideBar.classList.toggle('active')
		this.classList.toggle('active')
	}

	links.forEach((element) => {
		element.onclick = function() {
			links.forEach((el)=>{
				el.classList.remove('active')
			})
			sideBar.classList.remove('active')
			//this.classList.add('active')
		}
	})
}

let elements = document.querySelectorAll('.anchor-section')
let position
let id
let toActivate
let images = document.querySelectorAll('.sidebar-images .image');
let idImage;
let imageToActivate;

if (elements) {
	window.onscroll = function() {
		elements.forEach((element)=>{
			position = element.getBoundingClientRect();

			if (position.y < 100 && position.y > 0) {

				links.forEach((el)=>{
					el.classList.remove('active')
				})
				id = element.getAttribute('id')
				toActivate = document.querySelector("[href='#"+id+"']")
				if (toActivate) {
					toActivate.classList.add('active')
				}
				//-IMAGES SWAP
				if(element.classList.contains('has-image')) {
					if(images) {
						images.forEach((el)=>{
							el.classList.remove('active')
						})
					}
					idImage = element.getAttribute('id')
					imageToActivate = document.getElementById(idImage+'-image');
					if (imageToActivate) {
						imageToActivate.classList.add('active')
					}
				}
			}

		})
	 };

}

