import 'a11y-dialog'
const modal = document.querySelectorAll('.modal-video')

modal.forEach((el) => {
	const modalVideo = el.querySelector('iframe, video')
	if (modalVideo) {
		el.addEventListener('show', function (event) {
			switch (modalVideo.tagName) {
				case 'IFRAME' : 
					let videoSrc = modalVideo.getAttribute('src')
					videoSrc = videoSrc.replace('autoplay=0','autoplay=1')
					modalVideo.setAttribute('src',videoSrc)
				break
				case 'VIDEO' : 
					modalVideo.play()
				break 
			}
		})
		el.addEventListener('hide', function (event) {
			switch (modalVideo.tagName) {
				case 'IFRAME' : 
					let videoSrc = modalVideo.getAttribute('src')
					videoSrc = videoSrc.replace('autoplay=1','autoplay=0')
					modalVideo.setAttribute('src',videoSrc)
				break
				case 'VIDEO' : 
					modalVideo.pause()
				break 
			}
		})
	}
})


