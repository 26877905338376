//import css
import "./scss/main.scss";

//import components js
import "./components/backtotop/backtotop.js";
import "./components/blockquote/blockquote.js";
import "./components/button/button.js";
import "./components/card-news/card-news.js";
import "./components/card-small/card-small.js";
import "./components/career-form/career-form.js";
import "./components/cols-3/cols-3.js";
import "./components/contact-form/contact-form.js";
import "./components/events-form/events-form.js";
import "./components/footer/footer.js";
import "./components/form-order/form-order.js";
import "./components/free-html/free-html.js";
import "./components/gmap/gmap.js";
import "./components/header/header.js";
import "./components/hero-blurred/hero-blurred.js";
import "./components/hero-card/hero-card.js";
import "./components/image/image.js";
import "./components/modal/modal.js";
import "./components/nav/nav.js";
import "./components/pagination/pagination.js";
import "./components/product-text/product-text.js";
import "./components/search-input-wrapper/search-input-wrapper.js";
import "./components/sections/sections.js";
import "./components/sidebar/sidebar.js";
import "./components/simple-cta/simple-cta.js";
import "./components/swiper-carousel/swiper-carousel.js";
import "./components/swiper-slide/swiper-slide.js";
import "./components/table/table.js";
import "./components/text-center/text-center.js";
import "./components/text-intro/text-intro.js";
import "./components/timeline/timeline.js";

import "./js/browser-detect";
import "./js/lazyload";

import AOS from 'aos';
import 'aos/dist/aos.css'
AOS.init();
