import Swiper from 'swiper';
import { EffectFade, Autoplay, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';

//------------------------hero carousel--------------------------
let hero = document.getElementsByClassName('hero-carousel')

if (hero) {
	const swiper = new Swiper('.hero-carousel', {
		// configure Swiper to use modules
		modules: [EffectFade, Autoplay, Navigation, Pagination],
		autoplay : {
			delay: 5000,
			disableOnInteraction: false,
		},
		effect: "fade",
		crossFade : true
	});
}
//------------------------highlight carousel--------------------------
let card3 = document.getElementsByClassName('card-3-carousel')

if (hero) {
	const swiper = new Swiper('.card-3-carousel', {
		// configure Swiper to use modules
		modules: [Autoplay, Navigation, Pagination],
		pagination: {
			el: '.swiper-pagination',
		},
		breakpoints : {
			768: {
				slidesPerView: 2,
			},
			992: {
				slidesPerView: 3,
			},
			1350 : {
				slidesPerView: 4,
			}
		}
	});
}

let card3Sidebar = document.getElementsByClassName('card-3-carousel-sidebar')

if (hero) {
	const swiper = new Swiper('.card-3-carousel-sidebar', {
		// configure Swiper to use modules
		modules: [Autoplay, Navigation, Pagination],
		pagination: {
			el: '.swiper-pagination',
		},
		breakpoints : {

			768: {
				slidesPerView: 2,
			},
			992: {
				slidesPerView: 2,
			},
			1350 : {
				slidesPerView: 3,
			}
		}
	});
}

